import * as yup from 'yup';

export const schema = yup
  .object({
    cod_produto: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();

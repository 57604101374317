import React, { createContext, useContext, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { getProductsForNf } from './services/api';
import {
  AlteracaoCustoNfBonificadaContextType,
  FornecedorInfo,
  NotaFiscal,
  NotaFiscalEntrada,
  Produto,
} from './protocols';

const AlteracaoCustoNfBonificadaContext = createContext(
  {} as AlteracaoCustoNfBonificadaContextType,
);

export function AlteracaoCustoProvider({ children }: any) {
  const {
    register,
    getValues,
    watch,
    setValue,
    control,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [nfEntrada, setNfEntrada] = useState<FornecedorInfo>();
  const [nfsSelecionadas, setNfsSelecionadas] = useState<NotaFiscal[]>([]);
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [fornecedorModal, setFornecedorModal] = useState<NotaFiscalEntrada>({
    label: '',
    cod_fornecedor: 0,
    value: 0,
  });
  const [somaQtdTotalBonf, setSomaQtdTotalBonf] = useState<number>(0);
  const [somaValTotalBonf, setSomaValTotalBonf] = useState<number>(0);
  const [vSaldoPerc, setVSaldoPerc] = useState<number>(0);
  const [vSaldoQtd, setvSaldoQtd] = useState<number>(0);
  const [vSaldoValor, setVSaldoValor] = useState<number>(0);

  const handleResetStates = (): void => {
    setVSaldoPerc(100);
    setvSaldoQtd(0);
    setVSaldoValor(0);
  };

  useEffect(() => {
    const somaQtdBonf = nfsSelecionadas.reduce(
      (acc, item) => acc + item.qtd_total_bonificado,
      0,
    );
    setSomaQtdTotalBonf(somaQtdBonf);

    const somaValBonf = nfsSelecionadas.reduce(
      (acc, item) => acc + item.val_total_bonificado,
      0,
    );
    setSomaValTotalBonf(somaValBonf);
  }, [nfsSelecionadas]);

  const onRowClick = async (param: GridRowParams): Promise<void> => {
    const row: FornecedorInfo = param.row as FornecedorInfo;
    setNfEntrada(row);
    setShowSearch(false);
    setValue('ratear', {
      value: 0,
      label: 'Valor',
    });
    try {
      const getProductsService = await getProductsForNf(
        row.cod_loja,
        row.cod_seq_nf,
      );
      const mapping = getProductsService.map((item: any) => ({
        ...item,
        per_rateio: 0.0,
        val_rateio: 0.0,
      }));
      setProdutos(mapping);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleResetData = (): void => {
    setValue('ratear', {
      value: 0,
      label: 'Valor',
    });
    setNfEntrada({
      des_fornecedor: '',
      num_cpf_cnpj: '',
      num_cpf_cnpj_view: '',
      cod_loja: 0,
      dta_entrada: null,
      dta_entrada_view: null,
      cod_fornecedor: 0,
      num_nf: 0,
      cod_pessoa: 0,
      flg_confirmar: false,
      flg_confirmar_view: '',
      num_serie_nf: '',
      cod_seq_nf: 0,
      id: 0,
    });
    setNfsSelecionadas([]);
    setProdutos([]);
    reset();
    setFornecedorModal({
      label: '',
      cod_fornecedor: 0,
      value: 0,
    });
    setShowSearch(true);
  };

  useEffect(() => {
    setFornecedorModal({
      value: nfEntrada?.cod_pessoa,
      cod_fornecedor: nfEntrada?.cod_fornecedor,
      label: nfEntrada?.des_fornecedor,
    });
  }, [nfEntrada]);

  const handleShowModal = (): void => {
    setOpenModal(!openModal);
    setShowModal(!showModal);
    setFornecedorModal({
      value: nfEntrada?.cod_pessoa,
      cod_fornecedor: nfEntrada?.cod_fornecedor,
      label: nfEntrada?.des_fornecedor,
    });
  };

  const contextValue: AlteracaoCustoNfBonificadaContextType = {
    register,
    errors,
    loading,
    setLoading,
    setFornecedorModal,
    fornecedorModal,
    loadingItems,
    showSearch,
    setShowSearch,
    openModal,
    produtos,
    watch,
    setOpenModal,
    nfsSelecionadas,
    setNfsSelecionadas,
    setProdutos,
    onRowClick,
    handleShowModal,
    showModal,
    somaValTotalBonf,
    somaQtdTotalBonf,
    nfEntrada,
    handleResetData,
    setValue,
    getValues,
    control,
    reset,
    setError,
    clearErrors,
    handleResetStates,
    vSaldoValor,
    setVSaldoValor,
    vSaldoQtd,
    setvSaldoQtd,
    vSaldoPerc,
    setVSaldoPerc,
  };
  return (
    <AlteracaoCustoNfBonificadaContext.Provider value={contextValue}>
      {children}
    </AlteracaoCustoNfBonificadaContext.Provider>
  );
}

export function useAlteracaoCustoNfBonificada() {
  const context = useContext(AlteracaoCustoNfBonificadaContext);
  if (!context) {
    throw new Error(
      'useAlteracaoCusto deve ser usado dentro de um AlteracaoCustoProvider',
    );
  }
  return context;
}

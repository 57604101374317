import React, { memo, useState } from 'react';
import { ButtonForm, ButtonSeparator, Container } from './style';
import { IoIosSearch } from 'react-icons/io';
import Separator from '~/components/Separator';
import { Modal } from './components/Modal';
import { useAlteracaoCustoNfBonificada } from './AlteracaoNfBonificadaContext';
import { toast } from 'react-toastify';
import { CustoSemIcms } from './functions/CustoSemIcms';
import { calculaPMZ } from '../EntradaNFE/functions/Calculos';
import { updateItemsAndNfs } from './services/api';
import { Produto } from './protocols';
import { ratearNF } from './functions/RatearPorNf';
import { TableFornecedor } from './components/TableFornecedor';
import { TableNfSelecionada } from './components/TableNfSelecionada';
import { TableProduto } from './components/TableProdutos';
import FormDefault from '~/components/FormDefault';
import Search from '~/components/Search';
import DefaultLoader from '~/components/DefaultLoader';
import Tooltip from '@atlaskit/tooltip';

const AlteracaoDeCustoPorNFBonificadaContent: React.FC = () => {
  const {
    showSearch,
    openModal,
    nfsSelecionadas,
    produtos,
    handleShowModal,
    onRowClick,
    somaValTotalBonf,
    somaQtdTotalBonf,
    nfEntrada,
    handleResetData,
    watch,
    handleResetStates,
    reset,
  } = useAlteracaoCustoNfBonificada();

  const ratear = watch('ratear')?.value;

  const [loader, setLoader] = useState<boolean>(false);

  function calcTotais(): Record<string, number> {
    const totalValRateio = produtos.reduce((acc: number, item: Produto) => {
      return acc + item.val_rateio;
    }, 0);

    const totalQtdRateio = produtos.reduce((acc: number, item: Produto) => {
      const sum = acc + item.qtd_rateada - item.qtd_total_bonificado_rateado;
      return parseFloat(sum.toFixed(3));
    }, 0);

    return {
      totalQtdRateio,
      totalValRateio,
    };
  }

  function existeNfBonificada(): void {
    if (nfsSelecionadas.length === 0)
      throw new Error(
        'É necessário adicionar um NF bonificada para aplicar o rateio.',
      );
  }

  function verificaRateioProduto(
    totalValRateio: number,
    totalQtdRateio: number,
  ): void {
    switch (ratear) {
      case 0:
        if (totalValRateio > somaValTotalBonf) {
          throw new Error(
            'Valor do Rateio não pode ser maior que a Bonificação',
          );
        }
        break;
      case 1:
        if (totalQtdRateio > somaQtdTotalBonf) {
          throw new Error(
            'Quantidade do Rateio não pode ser maior que a Bonificação',
          );
        }
        break;
      default:
        break;
    }
  }

  function validaProdutoRateio(): void {
    const hasZeroValue = produtos.some((prod: Produto) => {
      if (ratear === 0) {
        return prod.val_rateio > 0;
      }
      return prod.per_rateio > 0;
    });

    if (!hasZeroValue) {
      if (ratear === 0) {
        throw new Error('Valor do rateio deve ser informado');
      } else if (ratear === 1) {
        throw new Error('Percentual do rateio deve ser informado');
      }
    }
  }

  function retiraProdutosSemValorRateio(): Produto[] {
    const newProdutosSemZero = produtos.filter((prod: Produto) => {
      if (ratear === 0) {
        return prod.val_rateio !== 0;
      }
      return prod.per_rateio !== 0;
    });
    return newProdutosSemZero;
  }

  function executaETrataCondicao(): boolean {
    const { totalValRateio, totalQtdRateio } = calcTotais();
    try {
      existeNfBonificada();
    } catch (err: any) {
      toast.warn(err?.message);
      return false;
    }
    try {
      verificaRateioProduto(totalValRateio, totalQtdRateio);
    } catch (err: any) {
      toast.warn(err?.message);
      return false;
    }
    try {
      validaProdutoRateio();
    } catch (err: any) {
      toast.warn(err?.message);
      return false;
    }
    return true;
  }

  async function RatearValNf(): Promise<void> {
    const newNf = ratearNF(produtos, ratear, nfsSelecionadas);
    const condicoes = executaETrataCondicao();
    const produtosComRateio = retiraProdutosSemValorRateio();
    const newProdutos = updateProdutos(produtosComRateio);
    if (condicoes) {
      try {
        setLoader(true);
        const reqAplicarRateio = await updateItemsAndNfs(
          newProdutos,
          newNf,
          ratear,
        );
        toast.success(reqAplicarRateio);
        handleResetData();
        reset();
        handleResetStates();
        setLoader(false);
        return;
      } catch (error) {
        setLoader(false);
        toast.warning(error);
      }
    }
  }

  function updateProdutos(produtosComRateio: Produto[]): Produto[] {
    let vValImpostoCred;
    const newProduto = produtosComRateio.map((produto: Produto) => {
      if (produto.val_rateio > 0 || produto.per_rateio > 0) {
        const vValCustoSIcms = CustoSemIcms(
          produto.val_custo_rep_novo,
          produto.per_icms_entrada,
        );
        produto.val_custo_sicms = vValCustoSIcms;
        const vValCredIcms =
          Number(vValCustoSIcms) / (1 - produto.per_icms_entrada / 100) -
          Number(vValCustoSIcms);
        const vValCredPisConfis =
          produto.val_custo_rep_novo * produto.per_pis_entrada +
          produto.per_cofins_entrada / 100;
        if (produto.flg_nao_pis_cofins) {
          vValImpostoCred = vValCredIcms;
        } else {
          vValImpostoCred = vValCustoSIcms + vValCredPisConfis;
        }
        const vValCustoSCred =
          produto.val_custo_rep_novo - Number(vValImpostoCred);
        const valPmz = calculaPMZ(
          vValCustoSCred,
          produto.per_icms_saida,
          produto.per_desp_op,
          produto.per_pis_entrada,
          produto.per_cofins_entrada,
          produto.flg_nao_pis_cofins,
          0.0,
        );
        produto.val_pmz = valPmz;
        produto.val_custo_scred = vValCustoSCred;
        produto.val_imposto_credito = vValImpostoCred;
      }
      return produto;
    });
    return newProduto;
  }

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <DefaultLoader />;
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={213}
          newData={() => []}
          onRowClick={onRowClick}
          onlyReport
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={213}
          title="Alteração de Custo por Entrada de NF Bonificada"
          codigoRegistro={[
            {
              value: nfEntrada.num_nf,
              des_campo: 'Nº. NF',
            },
          ]}
          onSave={async () => {
            RatearValNf();
          }}
          showIconSave={false}
          onCancel={() => {
            handleResetData();
            handleResetStates();
          }}
          isUpdate
          onNew={() => []}
          onDelete={() => []}
          onClearFields={() => {
            handleResetData();
            handleResetStates();
          }}
          onReturnSearch={() => {
            handleResetData();
            handleResetStates();
          }}
          isClear={false}
          isSave={false}
          labelButtonSave="Aplicar Alterações"
          isNew={false}
          isDelete={false}
        >
          <Separator labelText="NF a Receber o Rateio" />
          <TableFornecedor />
          <Separator
            labelText="NF Bonificada para Rateio"
            childrenWidth="230px"
          >
            <ButtonSeparator
              type="button"
              style={{
                marginTop: '-7px',
              }}
              onClick={handleShowModal}
            >
              <IoIosSearch
                focusable={false}
                aria-hidden="true"
                size={20}
                style={{
                  marginTop: '-2px',
                  color: 'green',
                  marginRight: '10px',
                }}
              />
              Buscar NF Bonificada
            </ButtonSeparator>
          </Separator>
          {openModal && <Modal />}
          <TableNfSelecionada />
          <Separator
            labelText="Produtos a receber o Rateio"
            childrenWidth="230px"
          />
          <TableProduto />
        </FormDefault>
      )}
    </Container>
  );
};

const MemoContent = memo(AlteracaoDeCustoPorNFBonificadaContent);

export default MemoContent;
